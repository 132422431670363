import React, { useState } from "react";
import { loginUser, resetPassword, registerUser } from "../firebase";
import "../Login.css";

function Login({ onLogin }) {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [registerUsername, setRegisterUsername] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [showRegister, setShowRegister] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [message, setMessage] = useState("");

  const [showPassword, setShowPassword] = useState(false); // Sichtbarkeit des Passworts

  const VALID_AUTH_CODE = "980665";

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");
    const result = await loginUser(identifier, password);
    if (typeof result === "string") {
      setError(result);
    } else {
      const { user, role } = result;
      onLogin({ user, isAdmin: role === "admin" });
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (registerPassword !== confirmPassword) {
      setError("Die Passwörter stimmen nicht überein.");
      return;
    }

    if (authCode !== VALID_AUTH_CODE) {
      setError("Ungültiger Authentifizierungscode.");
      return;
    }

    const result = await registerUser(
      registerEmail,
      registerPassword,
      registerUsername,
      "customer"
    );
    if (result) {
      setError(result);
    } else {
      setSuccess("Registrierung erfolgreich! Sie können sich jetzt einloggen.");
      setRegisterUsername("");
      setRegisterEmail("");
      setRegisterPassword("");
      setConfirmPassword("");
      setAuthCode("");
      setShowRegister(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");
    const result = await resetPassword(resetEmail);
    if (result) {
      setError(result);
    } else {
      setMessage("Passwort-Wiederherstellungs-E-Mail wurde gesendet.");
    }
  };

  return (
    <div className="login">
      {showRegister ? (
        <>
          <h2>Registrieren</h2>
          {error && <p className="error">{error}</p>}
          {success && <p className="success">{success}</p>}
          <form onSubmit={handleRegister}>
            <input
              type="text"
              placeholder="Benutzername"
              value={registerUsername}
              onChange={(e) => setRegisterUsername(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="E-Mail"
              value={registerEmail}
              onChange={(e) => setRegisterEmail(e.target.value)}
              required
            />
            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Passwort"
                value={registerPassword}
                onChange={(e) => setRegisterPassword(e.target.value)}
                required
              />
              <span
                className="eye-icon"
                onClick={togglePasswordVisibility}
              >
                <i className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
              </span>
            </div>
            <input
              type="password"
              placeholder="Passwort wiederholen"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Authentifizierungscode"
              value={authCode}
              onChange={(e) => setAuthCode(e.target.value)}
              required
            />
            <button type="submit">Registrieren</button>
          </form>
          <button
            className="toggle-login"
            onClick={() => setShowRegister(false)}
          >
            Bereits einen Account? Jetzt einloggen
          </button>
        </>
      ) : (
        <>
          <h2>Login</h2>
          {error && <p className="error">{error}</p>}
          {message && <p className="message">{message}</p>}
          <form onSubmit={handleLogin}>
            <input
              type="text"
              placeholder="Benutzername oder E-Mail"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              required
            />
            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Passwort"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span
                className="eye-icon"
                onClick={togglePasswordVisibility}
              >
                <i className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
              </span>
            </div>
            <button type="submit">Einloggen</button>
          </form>
          <button
            className="forgot-password"
            onClick={() => setShowRegister(true)}
          >
            Noch keinen Account? Jetzt registrieren
          </button>
          <form onSubmit={handleResetPassword}>
            <input
              type="email"
              placeholder="E-Mail für Passwort zurücksetzen"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
            />
            <button type="submit">Passwort zurücksetzen</button>
          </form>
        </>
      )}
    </div>
  );
}

export default Login;
