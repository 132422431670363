import React, { useState } from 'react';
import { registerUser } from '../firebase';

function Register() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('customer'); // Standardrolle: Kunde
  const [authCode, setAuthCode] = useState(''); // Authentifizierungscode-Feld
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const VALID_AUTH_CODE = '980665'; // Dein Authentifizierungscode

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (password !== confirmPassword) {
      setError('Die Passwörter stimmen nicht überein.');
      return;
    }

    if (authCode !== VALID_AUTH_CODE) {
      setError('Ungültiger Authentifizierungscode.');
      return;
    }

    const result = await registerUser(email, password, username, role);
    if (result) {
      setError(result);
    } else {
      setSuccess('Registrierung erfolgreich! Sie können sich jetzt einloggen.');
      setUsername('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setRole('customer'); // Zurücksetzen auf Standardrolle
      setAuthCode(''); // Authentifizierungscode zurücksetzen
    }
  };

  return (
    <div className="register container">
      <h2>Registrieren</h2>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      <form onSubmit={handleRegister}>
        <input
          type="text"
          placeholder="Benutzerhomo"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="E-Mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Passwort"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Passwort wiederholen"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Authentifizierungscode"
          value={authCode}
          onChange={(e) => setAuthCode(e.target.value)}
          required
        />
        <select
          value={role}
          onChange={(e) => setRole(e.target.value)}
        >
          <option value="customer">Kunde</option>
          <option value="admin">Administrator</option>
        </select>
        <button type="submit">Registrieren</button>
      </form>
    </div>
  );
}

export default Register;
