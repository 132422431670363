import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import "../AppStyles.css";

function AdminPanel() {
  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editingAppointment, setEditingAppointment] = useState(null);
  const [formData, setFormData] = useState({
    time: "",
    service: "",
    date: "",
  });
  const [availableTimes, setAvailableTimes] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);

  const allTimes = ["19:00", "19:30", "20:00", "20:30", "21:00", "21:30"];

  useEffect(() => {
    fetchAppointments();
    generateAvailableDates();
  }, []);

  const fetchAppointments = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "appointments"));
      const appointmentsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAppointments(appointmentsList);
    } catch (error) {
      console.error("Fehler beim Abrufen der Termine:", error);
      setError("Fehler beim Laden der Termine.");
    }
  };

  const generateAvailableDates = () => {
    const dates = [];
    const today = new Date();
    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      if (isWeekday(nextDate)) {
        dates.push(nextDate);
      }
    }
    setAvailableDates(dates);
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6; // Kein Sonntag (0) oder Samstag (6)
  };

  const handleEdit = (appointment) => {
    setEditMode(true);
    setEditingAppointment(appointment);
    setFormData({
      time: appointment.time,
      service: appointment.service,
      date: new Date(appointment.date.seconds * 1000).toISOString().split("T")[0],
    });

    const bookedTimes = appointments
      .filter(
        (appt) =>
          appt.date.seconds === appointment.date.seconds &&
          appt.id !== appointment.id
      )
      .map((appt) => appt.time);

    const freeTimes = allTimes.filter((time) => !bookedTimes.includes(time));
    setAvailableTimes(freeTimes);
  };

  const handleSave = async () => {
    try {
      if (!editingAppointment) return;

      const isDuplicate = appointments.some(
        (appt) =>
          new Date(appt.date.seconds * 1000).toISOString().split("T")[0] ===
            formData.date &&
          appt.time === formData.time &&
          appt.id !== editingAppointment.id
      );

      if (isDuplicate) {
        setError("Diese Zeit ist bereits gebucht. Bitte wählen Sie eine andere Zeit.");
        return;
      }

      const updatedDate = new Date(formData.date);
      const timestamp = Timestamp.fromDate(updatedDate);

      await updateDoc(doc(db, "appointments", editingAppointment.id), {
        time: formData.time,
        service: formData.service,
        date: timestamp,
      });

      setAppointments((prev) =>
        prev.map((appt) =>
          appt.id === editingAppointment.id
            ? { ...appt, ...formData, date: { seconds: timestamp.seconds } }
            : appt
        )
      );
      setEditMode(false);
      setEditingAppointment(null);
      setFormData({ time: "", service: "", date: "" });
      setError("");
    } catch (error) {
      console.error("Fehler beim Speichern der Änderungen:", error);
      setError("Fehler beim Speichern der Änderungen.");
    }
  };

  const handleCancel = () => {
    setEditMode(false);
    setEditingAppointment(null);
    setFormData({ time: "", service: "", date: "" });
    setAvailableTimes([]);
  };

  const handleDelete = async (appointmentId) => {
    try {
      await deleteDoc(doc(db, "appointments", appointmentId));
      setAppointments((prev) =>
        prev.filter((appointment) => appointment.id !== appointmentId)
      );
    } catch (error) {
      console.error("Fehler beim Löschen des Termins:", error);
      setError("Fehler beim Löschen des Termins.");
    }
  };

  return (
    <div className="container">
      <div className="header">
        <h2>Admin Panel</h2>
      </div>
      {error && <p className="error">{error}</p>}

      {editMode ? (
        <div className="form-section">
          <h3>Termin bearbeiten</h3>
          <select
            value={formData.date}
            onChange={(e) => setFormData({ ...formData, date: e.target.value })}
          >
            <option value="">Datum wählen</option>
            {availableDates.map((date) => (
              <option key={date.toISOString()} value={date.toISOString().split("T")[0]}>
                {date.toLocaleDateString("de-DE")}
              </option>
            ))}
          </select>
          <select
            value={formData.time}
            onChange={(e) => setFormData({ ...formData, time: e.target.value })}
          >
            <option value="">Zeit wählen</option>
            {availableTimes.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Dienstleistung"
            value={formData.service}
            onChange={(e) => setFormData({ ...formData, service: e.target.value })}
          />
          <button onClick={handleSave}>Speichern</button>
          <button onClick={handleCancel}>Abbrechen</button>
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Datum</th>
              <th>Zeit</th>
              <th>Dienstleistung</th>
              <th>Aktionen</th>
            </tr>
          </thead>
          <tbody>
            {appointments.length > 0 ? (
              appointments.map((appointment) => (
                <tr key={appointment.id}>
                  <td>{appointment.name}</td>
                  <td>
                    {new Date(
                      appointment.date.seconds * 1000
                    ).toLocaleDateString("de-DE")}
                  </td>
                  <td>{appointment.time}</td>
                  <td>{appointment.service}</td>
                  <td>
                    <button onClick={() => handleEdit(appointment)}>Bearbeiten</button>
                    <button onClick={() => handleDelete(appointment.id)}>Löschen</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="no-data">
                  Keine Buchungen vorhanden
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default AdminPanel;
