import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/Login";
import AdminPanel from "./components/AdminPanel";
import AppointmentForm from "./components/AppointmentForm";
import Register from "./components/Register"; // Registrierung importieren
import "./AppStyles.css";

function App() {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleLogin = (userInfo) => {
    setUser(userInfo.user);
    setIsAdmin(userInfo.isAdmin);
  };

  const handleLogout = () => {
    setUser(null);
    setIsAdmin(false);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/login"
            element={
              user ? (
                <Navigate to={isAdmin ? "/admin" : "/appointments"} replace />
              ) : (
                <Login onLogin={handleLogin} />
              )
            }
          />
          <Route
            path="/register"
            element={<Register />} // Route für die Registrierung
          />
          <Route
            path="/admin"
            element={
              user && isAdmin ? (
                <AdminPanel onLogout={handleLogout} />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/appointments"
            element={
              user && !isAdmin ? (
                <AppointmentForm onLogout={handleLogout} />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/"
            element={<Navigate to="/login" replace />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
