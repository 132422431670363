import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  Timestamp,
} from "firebase/firestore";

// Firebase-Konfiguration
const firebaseConfig = {
  apiKey: "AIzaSyBr9dgelk-_t6GpQmd-JJGxfLLheD0h9HY",
  authDomain: "saficuts-559d7.firebaseapp.com",
  projectId: "saficuts-559d7",
  storageBucket: "saficuts-559d7.appspot.com",
  messagingSenderId: "233978700332",
  appId: "1:233978700332:web:df913cb1221c7e8b1c2789",
  measurementId: "G-NMS6738745",
};

// Firebase initialisieren
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

/**
 * Registriert einen neuen Benutzer und speichert Benutzernamen und Rolle in Firestore.
 * @param {string} email - Die E-Mail-Adresse des neuen Benutzers.
 * @param {string} password - Das Passwort des neuen Benutzers.
 * @param {string} username - Der Benutzername des neuen Benutzers.
 * @param {string} role - Die Rolle des Benutzers (z.B. "customer" oder "admin").
 * @returns {Promise<string|null>} - Eine Fehlermeldung oder null, wenn erfolgreich.
 */
export async function registerUser(email, password, username, role = "customer") {
  try {
    // Verhindere doppelte Admin-Registrierungen
    if (role === "admin") {
      const adminQuery = query(collection(db, "users"), where("role", "==", "admin"));
      const adminSnapshot = await getDocs(adminQuery);
      if (!adminSnapshot.empty) {
        return "Ein Administrator existiert bereits.";
      }
    }

    // Prüfen, ob der Benutzername bereits existiert
    const usernameQuery = query(collection(db, "users"), where("username", "==", username));
    const usernameSnapshot = await getDocs(usernameQuery);
    if (!usernameSnapshot.empty) {
      return "Dieser Benutzername wird bereits verwendet.";
    }

    // Benutzer registrieren
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Benutzername in Firebase Auth und Firestore speichern
    await updateProfile(user, { displayName: username });
    await setDoc(doc(db, "users", user.uid), {
      email,
      username,
      role,
    });

    return null;
  } catch (error) {
    console.error("Fehler bei der Registrierung:", error);
    if (error.code === "auth/email-already-in-use") {
      return "Diese E-Mail-Adresse wird bereits verwendet.";
    }
    return `Registrierung fehlgeschlagen: ${error.message}`;
  }
}

/**
 * Funktion zum Einloggen eines Benutzers mit E-Mail oder Benutzername und Passwort.
 * @param {string} identifier - Die E-Mail-Adresse oder der Benutzername des Benutzers.
 * @param {string} password - Das Passwort des Benutzers.
 * @returns {Promise<{user: object, role: string} | string>} - Die Benutzerinformationen und Rolle oder eine Fehlermeldung.
 */
export async function loginUser(identifier, password) {
  try {
    let userCredential;

    // Prüfen, ob die Eingabe eine E-Mail-Adresse ist
    if (identifier.includes("@")) {
      userCredential = await signInWithEmailAndPassword(auth, identifier, password);
    } else {
      // Benutzername zu E-Mail mappen
      const usernameQuery = query(collection(db, "users"), where("username", "==", identifier));
      const usernameSnapshot = await getDocs(usernameQuery);
      if (usernameSnapshot.empty) {
        return "Benutzername nicht gefunden.";
      }
      const userDoc = usernameSnapshot.docs[0];
      const email = userDoc.data().email;

      userCredential = await signInWithEmailAndPassword(auth, email, password);
    }

    const user = userCredential.user;

    // Zusätzliche Informationen aus Firestore laden
    const userDoc = await getDoc(doc(db, "users", user.uid));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      user.displayName = userData.username; // Benutzername synchronisieren
      const role = userData.role;
      return { user, role };
    }

    return "Benutzerinformationen konnten nicht abgerufen werden.";
  } catch (error) {
    console.error("Fehler beim Einloggen:", error);
    return "Login fehlgeschlagen.";
  }
}

/**
 * Funktion zum Zurücksetzen des Passworts eines Benutzers.
 * @param {string} email - Die E-Mail-Adresse des Benutzers.
 * @returns {Promise<string|null>} - Eine Fehlermeldung oder null, wenn erfolgreich.
 */
export async function resetPassword(email) {
  try {
    await sendPasswordResetEmail(auth, email);
    return null;
  } catch (error) {
    console.error("Fehler beim Zurücksetzen des Passworts:", error);
    return "Fehler beim Zurücksetzen des Passworts.";
  }
}
