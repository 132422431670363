import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../firebase";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import "../AppointmentForm.css";

function AppointmentForm({ onLogout }) {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [service, setService] = useState("");
  const [availableTimes, setAvailableTimes] = useState([]);
  const [existingAppointment, setExistingAppointment] = useState(null);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    const checkAuthentication = async () => {
      const user = auth.currentUser;

      if (!user) {
        setError("Benutzer ist nicht authentifiziert.");
        navigate("/login");
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          setUsername(userDoc.data().username);
        } else {
          setError("Benutzername konnte nicht gefunden werden.");
        }
      } catch (err) {
        console.error("Fehler beim Abrufen des Benutzernamens:", err);
        setError("Fehler beim Abrufen des Benutzernamens.");
      } finally {
        setLoading(false);
      }
    };

    checkAuthentication();
  }, [navigate]);

  useEffect(() => {
    if (username) {
      fetchAvailableTimes();
      checkExistingAppointment();
    }
  }, [selectedDate, username]);

  const fetchAvailableTimes = async () => {
    try {
      const start = new Date(selectedDate);
      start.setHours(19, 0, 0);
      const end = new Date(selectedDate);
      end.setHours(22, 0, 0);

      const q = query(
        collection(db, "appointments"),
        where("date", ">=", Timestamp.fromDate(start)),
        where("date", "<=", Timestamp.fromDate(end))
      );

      const querySnapshot = await getDocs(q);
      const bookedTimes = querySnapshot.docs.map((doc) => doc.data().time);
      const slots = generateTimeSlots(start, end).filter(
        (slot) => !bookedTimes.includes(slot)
      );
      setAvailableTimes(slots);
    } catch (err) {
      console.error("Fehler beim Laden der verfügbaren Zeiten:", err);
      setError("Fehler beim Laden der verfügbaren Zeiten.");
    }
  };

  const checkExistingAppointment = async () => {
    if (!username) return;

    try {
      const q = query(
        collection(db, "appointments"),
        where("name", "==", username)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const appointment = querySnapshot.docs[0];
        setExistingAppointment({ id: appointment.id, ...appointment.data() });
      } else {
        setExistingAppointment(null);
      }
    } catch (err) {
      console.error("Fehler beim Überprüfen des bestehenden Termins:", err);
      setError("Fehler beim Überprüfen des bestehenden Termins.");
    }
  };

  const generateTimeSlots = (start, end) => {
    const slots = [];
    let current = new Date(start);
    while (current < end) {
      slots.push(current.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }));
      current.setMinutes(current.getMinutes() + 30);
    }
    return slots;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (!username) {
      setError("Benutzername konnte nicht geladen werden. Bitte laden Sie die Seite neu.");
      return;
    }

    if (existingAppointment) {
      setError("Sie haben bereits einen Termin. Bitte löschen Sie diesen zuerst.");
      return;
    }

    if (!time || !service) {
      setError("Bitte wählen Sie eine Zeit und Dienstleistung aus.");
      return;
    }

    try {
      const selectedDateTime = new Date(selectedDate);
      const [hours, minutes] = time.split(":").map(Number);
      selectedDateTime.setHours(hours, minutes, 0, 0);

      await addDoc(collection(db, "appointments"), {
        name: username,
        time,
        service,
        date: Timestamp.fromDate(selectedDateTime),
      });

      setTime("");
      setService("");
      setSuccess("Termin erfolgreich gebucht!");
      setExistingAppointment(null); // Temporär entfernen

      // Timer für erneute Prüfung
      setTimeout(() => {
        checkExistingAppointment();
      }, 60000); // 1 Minute Verzögerung
    } catch (err) {
      console.error("Fehler beim Buchen des Termins:", err);
      setError("Fehler beim Buchen des Termins. Bitte versuchen Sie es erneut.");
    }
  };

  const handleDeleteAppointment = async () => {
    if (!existingAppointment) return;

    try {
      await deleteDoc(doc(db, "appointments", existingAppointment.id));
      setExistingAppointment(null);
      setSuccess("Ihr bestehender Termin wurde erfolgreich gelöscht.");
    } catch (err) {
      console.error("Fehler beim Löschen des Termins:", err);
      setError("Fehler beim Löschen des Termins. Bitte versuchen Sie es erneut.");
    }
  };

  const handleDateChange = (date) => {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 7);
    if (isWeekday(date) && date <= maxDate) {
      setSelectedDate(date);
      setSuccess("");
      setError("");
    } else if (!isWeekday(date)) {
      setError("Bitte wählen Sie einen Wochentag (Montag bis Freitag).");
    } else {
      setError("Sie können nur eine Woche im Voraus buchen.");
    }
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const handleLogout = () => {
    onLogout();
    navigate("/login");
  };

  return (
    <div className="appointment-form container">
      <div className="header">
        <h2>Termin buchen</h2>
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      {loading ? (
        <p>Lade Benutzerdaten...</p>
      ) : existingAppointment ? (
        <div className="existing-appointment">
          <p>
            Sie haben bereits einen Termin am{" "}
            {new Date(existingAppointment.date.seconds * 1000).toLocaleDateString()} um{" "}
            {existingAppointment.time}.
          </p>
          <button
            className="delete-button bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
            onClick={handleDeleteAppointment}
          >
            Termin löschen
          </button>
        </div>
      ) : (
        <>
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            minDate={new Date()}
            maxDate={new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)}
            tileDisabled={({ date }) => !isWeekday(date)}
          />
          <form onSubmit={handleSubmit} className="mt-4 space-y-4">
            <select
              value={time}
              onChange={(e) => setTime(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Zeit wählen</option>
              {availableTimes.map((slot) => (
                <option key={slot} value={slot}>
                  {slot}
                </option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Dienstleistung"
              value={service}
              onChange={(e) => setService(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full"
            >
              Buchen
            </button>
          </form>
        </>
      )}
    </div>
  );
}

export default AppointmentForm;
